import { db } from "./firebase";

import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
} from "firebase/firestore";

const usersCollectionRef = collection(db, "customers");
const locationRef = collection(db, "location");
const expensesRef = collection(db, "expenses");
const userDataRef = collection(db, "userData");
const jobDataRef = collection(db, "jobs");

const docRef = doc(db, "location", "user_loc");

class UsersDataServices {
  addUsers = (newUser) => {
    return addDoc(usersCollectionRef, newUser);
  };

  updateUser = async (id, updatedUser) => {
    // console.log(updatedUser, "id", id);
    const userDoc = doc(db, "customers", id);
    // console.log("finally yahan bhi");
    return await updateDoc(userDoc, updatedUser);
  };

  deleteUser = (id) => {
    const userDoc = doc(db, "customers", id);
    return deleteDoc(userDoc);
  };

  getAllUsers = async () => {
    const data = await getDocs(usersCollectionRef);
    // console.log("usersss", data);
    return data;
  };

  getUser = (id) => {
    const userDoc = doc(db, "customers", id);
    return getDoc(userDoc);
  };

  // --- locations ---
  getLocations = async () => {
    const data = await db
      .collection("location")
      .doc("user_loc")
      .collection("+918587010116")
      .get();

    // const res = await data.docs.forEach((item) => {
    //   console.log("aujoijaofoajfla", item.data());
    // });

    // const collections = await docRef.listCollections();
    // console.log("locationRef", collections);
    // console.log("this is the location data from services", data);
    // console.log("datasnapshot", data.id);
    // console.log("from services", data?.docs);
    return data;
  };

  // --- expenses ---
  getExpenses = () => {
    const data = getDocs(expensesRef);
    return data;
  };

  // --- customer data ---
  getCustomerData = () => {
    const data = getDocs(userDataRef);
    return data;
  };

  // --- jobs data ---
  getJobData = () => {
    const data = getDocs(jobDataRef);
    return data;
  };

  addTask = (task) => {
    return addDoc(jobDataRef, task);
  };

  deleteTask = (id) => {
    const userDoc = doc(jobDataRef, id);
    return deleteDoc(userDoc);
  };
}

const userDataService = new UsersDataServices();

export default userDataService;
