import { getAuth } from 'firebase/auth'
import { initializeApp } from "firebase/app";

// import { getFirestore } from "firebase/firestore"
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// import firebase from 'firebase/compat/app'
import 'firebase/compat/storage'

const firebaseConfig = {
    apiKey: "AIzaSyBQr5jIQ9_iAAHos_ZTFvE_VVKweDiLLoY",
    authDomain: "tracerz-51431.firebaseapp.com",
    projectId: "tracerz-51431",
    storageBucket: "tracerz-51431.appspot.com",
    messagingSenderId: "900517899375",
    appId: "1:900517899375:web:08fa0c1e8d087cec59f19a",
    measurementId: "G-KRVNH7D886"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();

firebase.initializeApp(firebaseConfig);
export const storage = firebase.storage(); 

const auth = getAuth(app); 
export { auth };  
