import {
  IndianRupee,
  FileDown,
  RefreshCcw,
  Users,
  UserRound,
  Megaphone,
  Route,
  ShoppingBag,
  CheckCircle2,
} from "lucide-react";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import UsersDataServices from "../services";
import toast from "react-hot-toast";
import { motion } from "framer-motion";

const tableData = [
  {
    employee: "Alankar Saxena",
    expenses: "500",
    attendance: "26 / 30",
    task: "dummy task 01",
    travelHis: "250 km",
    NewCustomer: "naveen Patnaik",
    expenseApproved: false,
  },
  {
    employee: "Amar Jyothi",
    expenses: "500",
    attendance: "26 / 30",
    task: "dummy task 01",
    travelHis: "250 km",
    NewCustomer: "naveen Patnaik",
    expenseApproved: true,
  },
  {
    employee: "Akshat Atray",
    expenses: "500",
    attendance: "26 / 30",
    task: "dummy task 01",
    travelHis: "250 km",
    NewCustomer: "naveen Patnaik",
    expenseApproved: false,
  },
  {
    employee: "Shubham Chaturvedi",
    expenses: "500",
    attendance: "26 / 30",
    task: "dummy task 01",
    travelHis: "250 km",
    NewCustomer: "naveen Patnaik",
    expenseApproved: false,
  },
];

export default function Report() {
  const [users, setUsers] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [punchInData, setPuchInData] = useState([]);
  const [jobData, setJobData] = useState([]);

  const [showExpenses, setShowExpenses] = useState(false);
  const [rotate, setRotate] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showExpensesOptions, setShowExpenseOptions] = useState(false);
  const [selectedExpenseCell, setSelectedExpenseCell] = useState(null);

  useEffect(() => {
    getUsers();
    newFun();
    userPuchInData();
    jobDataDownload();
  }, [rotate]);

  const getUsers = async () => {
    const data = await UsersDataServices.getAllUsers();
    // console.log(data.docs);
    // setUsers(data);
    setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const newFun = async () => {
    const data = await UsersDataServices.getExpenses();
    setExpenses(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const userPuchInData = async () => {
    const data = await UsersDataServices.getCustomerData();
    setPuchInData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const jobDataDownload = async () => {
    const data = await UsersDataServices.getJobData();
    setJobData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const downloadExcel = (data, fileName) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  //   console.log(users, expenses, punchInData, jobData)
  const handleDownloadUserData = () => {
    // const combinedData = [...users, ...expenses]; // Combine your two states or use them separately
    const data = users;
    downloadExcel(data, "Customers Data");
  };

  const downloadExpenses = () => {
    downloadExcel(expenses, "Expenses Data");
    toast.success("Expense Excel Downloaded");
  };
  const totalExpenses = expenses.reduce(
    (sum, expense) => sum + parseFloat(expense.amount),
    0
  );

  const downloadPuchIn = () => {
    downloadExcel(punchInData, "Attendance Data");
  };

  const downloadJob = () => {
    downloadExcel(jobData, "Task Data");
  };
  console.log(expenses);
  console.log(selectedRow);

  return (
    <>
      <div className="m-3 w-full">
        <h2 className="text-black font-bold text-center mt-2 mb-3 w-full text-xl">
          Download Reports
        </h2>

        {/* <div className="shadow-3xl w-full text-white rounded-2xl cursor-pointer">
          <div className="p-2 bg-black rounded-t-2xl font-bold flex justify-between items-center">
            Download Expense Data
            <div className="flex gap-1 items-center">
              <motion.div animate={{ rotate: rotate ? 180 : 0 }} whileTap={{ scale: 1.5 }} onClick={() => setRotate(!rotate)}>
                <RefreshCcw />
              </motion.div>
              <FileDown
                onClick={downloadExpenses}
                color="#90ee90"
                size={25}
                className="my-auto cursor-pointer"
              />
            </div>
          </div>
          <div className="bg-gray-500 p-3 rounded-b-2xl flex gap-4">
            <div className="w-fit">
              <p className="text-3xl font-bold flex items-center">
                <IndianRupee />
                {totalExpenses}
              </p>
              <p className="text-green-300 text-sm">+25%</p>
            </div>
            <div>
              <p className="text-xs text-gray-100">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Laboriosam at numquam nihil, officia delectus cumque quis enim
                id cupiditate voluptate assumenda modi!
              </p>
              <p
                onClick={() => setShowExpenses(!showExpenses)}
                className="text-xs text-green-300 pt-3"
              >
                click here to download the individual data
              </p>
            </div>
          </div>
        </div>
        
        {showExpenses && (
          <div className="overflow-y-scroll h-[65vh]">
            {expenses?.map((data, index) => (
              <div className="my-3 flex w-full" key={index}>
                <p className="text-white w-[130px] bg-black p-2 rounded-l-2xl flex justify-center items-center text-xl font-bold">
                  <IndianRupee size={18} /> {data?.amount}
                </p>
                <div className="w-full flex bg-gray-500  rounded-r-2xl p-2">
                  <div className="w-full text-white ">
                    <p className="font-bold">{data?.user}</p>
                    <p className="text-xs">
                      {data?.reason}{" "}
                      <span className="text-green-300">
                        {" "}
                        on 21 Feb 2024: 08:10 am
                      </span>
                    </p>
                  </div>
                  <FileDown
                    color="#90ee90"
                    size={30}
                    className="my-auto cursor-pointer"
                  />
                </div>
              </div>
            ))}
          </div>
        )} */}

        <div className="rounded-md overflow-hidden border border-gray-500">
          <table className="w-full">
            <thead>
              <tr className="bg-black border-black border text-white">
                <th className="border p-2">Employee</th>
                <th className="border p-2">Expenses</th>
                <th className="border p-2">Attendance</th>
                <th className="border p-2">Task</th>
                <th className="border p-2">Travel History</th>
                <th className="border p-2">New Customer</th>
                <th className="border p-2 cursor-pointer">
                  <FileDown
                    onClick={downloadExpenses}
                    color="#90ee90"
                    size={25}
                    className="my-auto cursor-pointer"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((data, index) => (
                <>
                  {showExpensesOptions && selectedExpenseCell === index && (
                    <div className="cursor-pointer absolute bg-white p-2 border-red-400 border rounded-2xl shadow-2xl translate-x-[310px] translate-y-2">
                      <p className="text-xs text-white p-2 bg-green-600 rounded-2xl text-center">
                        Approve
                      </p>
                      <p className="text-xs text-white p-2 bg-red-600 rounded-2xl text-center mt-1">
                        Disapprove
                      </p>
                    </div>
                  )}
                  <tr
                    key={index}
                    className={`cursor-pointer ${
                      index % 2 === 0 ? "bg-white" : "bg-gray-100"
                    } ${selectedRow === index ? "bg-green-100" : ""}`}
                    onClick={() => setSelectedRow(index)}
                  >
                    <td className="border p-2">{data.employee}</td>
                    <td
                      className="border p-2 flex gap-1 items-center justify-between"
                      onClick={() => {
                        setShowExpenseOptions(!showExpensesOptions);
                        setSelectedExpenseCell(index);
                      }}
                    >
                      <div className="flex gap-1 items-center">
                      <IndianRupee size={16} />
                      {data.expenses}
                      </div>
                      {data.expenseApproved && <CheckCircle2 className="text-[#4cac4c]"/>}
                    </td>
                    <td className="border p-2">{data.attendance}</td>
                    <td className="border p-2">{data.task}</td>
                    <td className="border p-2">{data.travelHis}</td>
                    <td className="border p-2">{data.NewCustomer}</td>
                    <td className="border p-2 cursor-pointer">
                      <FileDown />
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
