import { useState } from "react";
import Navbar from "../components/Navbar";
import Landing from "./Landing";
import LocationLoader from "../components/LoactionLoader";
import Footer from '../components/Footer'

export default function Home() {
  const [loading, setLoading] = useState(true);
  setTimeout(() => {
    setLoading(false);
  }, 1000);

  if (loading) return <div className="w-[100%] h-[100vh] flex justify-center items-center"><LocationLoader /></div>
  else {
    return (
      <>
        <Navbar />
        <Landing />
        <Footer />
      </>
    );
  }
}
