import {
  Ban,
  CircleEllipsis,
  ClipboardMinus,
  ClipboardPlus,
  MapPin,
  MessageSquareMore,
  SquarePen,
  Trash2,
} from "lucide-react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import userDataService from "../services";
import Loader from "./Loader";

export default function Tasks() {
  const [addTask, setAddTask] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [newTask, setNewTask] = useState({
    title: "",
    description: "",
    forUser: "",
    location: "",
    date: "",
    completed: false,
    completionDate: "",
    user_id: null,
  });
  console.log(newTask);

  const [showFullDescription, setShowFullDescription] = useState(false);
  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const getTasks = async () => {
    const data = await userDataService.getJobData();
    // console.log(response);
    setTasks(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    setLoading(false);
  };
  const totalTasks = tasks.length;
  const tasksCompletedCount = tasks.filter(obj => obj?.completed === true).length;
  const tasksPendingCount = totalTasks - tasksCompletedCount;

  const saveTaskHandler = async () => {
    setButtonDisabled(true);
    await userDataService
      .addTask(newTask)
      .then((data) => {
        console.log(data);
        toast.success("Task Added Successfully");
        setButtonDisabled(false);
        setAddTask(false);
      })
      .catch((e) => {
        console.log(e);
        toast.error("Some error occured");
        setButtonDisabled(false);
      });
  };

  const handleConfirmDelete = (index) => {
    setConfirmDelete(index);
  };

  const convertToDate = (sec, nano) => {
    const seconds = sec;
    const nanoseconds = nano;

    const totalSeconds = seconds + nanoseconds / 1e9;

    const date = new Date(totalSeconds * 1000);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const dayOfWeek = date.toLocaleString("en-US", { weekday: "long" });
    const time = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });

    // Format date as dd/mm/yyyy, day, time
    const formattedDate = `${dayOfWeek}, ${day}/${month}/${year}, ${time}`;

    return formattedDate;
  };

  useEffect(() => {
    getTasks();
  }, []);
  console.log(tasks);

  if (loading)
    return (
      <div className="h-full w-[470px] flex justify-center items-center">
        <Loader className="animate-span h-10 w-10" />
      </div>
    );

  return (
    <div className="m-3 w-[470px]">
      {/* <p className="text-center font-bold text-xl my-1">Tasks</p> */}

      <div className="bg-green-50 flex justify-between items-center p-2 rounded-lg shadow-md border border-gray-300 my-2">
        <p className="">
          Total: <span className="font-bold text-blue-700">{totalTasks}</span>
        </p>
        <p className="">
          Completed: <span className="font-bold text-green-600">{tasksCompletedCount}</span>
        </p>
        <p className="">
          Pending: <span className="font-bold text-red-600">{tasksPendingCount}</span>
        </p>
      </div>

      <div className="flex justify-end items-center my-1">
        {/* <p className="font-bold text-xl">Tasks</p> */}
        {addTask ? (
          <div
            className="flex gap-1 fontbold text-red-600 items-center cursor-pointer"
            onClick={() => setAddTask(false)}
          >
            <p>Close</p>
            <ClipboardMinus color="red" className="cursor-pointer" />
          </div>
        ) : (
          <div
            className="flex gap-1 fontbold text-green-800 items-center cursor-pointer"
            onClick={() => setAddTask(true)}
          >
            <p>Add Task</p>
            <ClipboardPlus color="green" className="cursor-pointer" />
          </div>
        )}
      </div>

      {addTask && (
        <div className="flex flex-col gap-1 my-3">
          <input
            type="text"
            onChange={(e) => setNewTask(prev => ({...prev, title: e.target.value}))}
            placeholder="Job Title"
            className="border border-gray-300 rounded-lg p-2 placeholder-gray-500 focus:border-transparent"
          />
          <input
            type="text"
            onChange={(e) => setNewTask(prev => ({...prev, description: e.target.value}))}
            placeholder="Job Description"
            className="border border-gray-300 rounded-lg p-2 placeholder-gray-500"
          />
          <input
            type="text"
            onChange={(e) => setNewTask(prev => ({...prev, forUser: e.target.value}))}
            placeholder="For User"
            className="border border-gray-300 rounded-lg p-2 placeholder-gray-500"
          />
          <p className="mt-2 text-xs">
            (Add location on the map by searching on search bar on map)
          </p>
          <input
            type="text"
            onChange={(e) => setNewTask(prev => ({...prev, location: e.target.value}))}
            placeholder="Job Location"
            className="border border-gray-300 rounded-lg p-2 placeholder-gray-500"
          />
          <button
            disabled={buttonDisabled}
            onClick={saveTaskHandler}
            className={`p-2 rounded-lg text-center border-2 border-${
              buttonDisabled ? "gray" : "green"
            }-600 cursor-pointer ${
              buttonDisabled ? "bg-gray-100" : "bg-green-100"
            }`}
          >
            Save Task
          </button>
        </div>
      )}

      <div className="overflow-y-scroll h-[600px]">
        {tasks.map((data, index) => (
          <div
            key={index}
            className="p-2 shadow-md mt-2 rounded-lg border border-gray-500"
          >
            <div className="flex justify-between items-center">
              {data?.completed ? (
                <p className="p-1 text-sm font-bold rounded-lg border-2 text-green-700 border-green-200 bg-green-100 w-fit">
                  Completed
                </p>
              ) : (
                <p className="p-1 text-sm font-bold rounded-lg border-2 text-yellow-600 border-yellow-200 bg-yellow-100 w-fit">
                  Pending
                </p>
              )}
              <div className="flex items-center gap-1">
                {/* <MapPin size={24} color="gray" className="cursor-pointer" /> */}
                <SquarePen size={24} color="gray" className="cursor-pointer" />
              </div>
            </div>

            <div className="mt-3">
              <p className="font-bold">{data?.title}</p>
              <p className="text-gray-400 text-sm mt-1">
                {showFullDescription
                  ? data?.description
                  : `${data?.description.slice(0, 199)}...`}
                {data?.description.length > 199 && (
                  <span
                    className="cursor-pointer text-blue-500"
                    onClick={toggleDescription}
                  >
                    {showFullDescription ? " Show less" : " Show more"}
                  </span>
                )}
              </p>
              {data?.completionDate &&
              <p className="text-green-700 text-sm mt-1">
                Date of completion:{" "}
                {convertToDate(
                  data?.completionDate?.seconds,
                  data?.completionDate?.nanoseconds
                  )}
              </p>
                }
            </div>

            <div className="bg-[#f9f5f5] rounded-lg mt-3">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-3 p-2">
                  <p className="bg-gray-600 shadow-xl rounded-full h-[40px] text-white text-center w-[40px] flex items-center justify-center font-extrabold text-lg">
                    NN
                  </p>
                  <div>
                    <p className="font-bold">no name</p>
                    <p className="text-xs text-gray-500">{data?.forUser}</p>
                  </div>
                </div>
                {/* <p className="bg-green-100 p-1 h-fit text-sm rounded-lg mr-3 text-green-800 font-bold">
                Employee
              </p> */}
                <div
                  onClick={() => handleConfirmDelete(index)}
                  className="p-1 bg-red-50 border-2 border-red-700 rounded-lg text-red-700 cursor-pointer mr-2"
                >
                  <Trash2 />
                </div>
              </div>
              {confirmDelete === index && (
                <>
                  <div
                    onClick={() => setConfirmDelete(false)}
                    className="fixed left-0 right-0 bottom-0 top-0 bg-transparent opacity-80 z-10"
                  ></div>
                  <div className="m-2 p-1 bg-white rounded-lg w-fit cursor-pointer absolute translate-x-[310px] -translate-y-[60px] z-20">
                    <p className="text-red-700 border border-red-700 bg-red-100 p-1 rounded-md text-center">
                      Confirm
                    </p>
                    <p
                      className="text-gray-700 border border-gray-700 bg-gray-100 p-1 rounded-md mt-1 text-center"
                      onClick={() => setConfirmDelete(false)}
                    >
                      Cancel
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        ))}

        {/* <div className="p-2 shadow-md mt-2 rounded-lg border border-gray-500">
          <div className="flex justify-between items-center">
            <p className="p-1 text-sm font-bold rounded-lg border-2 border-yellow-500 bg-yellow-100 w-fit">
              Pending
            </p>
            <CircleEllipsis size={24} color="gray" className="cursor-pointer" />
          </div>

          <div className="mt-3">
            <p className="font-bold">Task 01 Name</p>
            <p className="text-gray-400 text-sm mt-1">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Hic, ab.
              Lorem, ipsum dolor.
            </p>
          </div>

          <div className="bg-[#f9f5f5] rounded-lg mt-3">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-3 p-2">
                <p className="bg-gray-600 shadow-xl rounded-full h-[40px] text-white text-center w-[40px] flex items-center justify-center font-extrabold text-lg">
                  AJ
                </p>
                <div>
                  <p className="font-bold">Amar Jyothi</p>
                  <p className="text-xs text-gray-500"> +91 99995 44443</p>
                </div>
              </div>
              <p className="bg-green-100 p-1 h-fit text-sm rounded-lg mr-3 text-green-800 font-bold">
                Employee
              </p>
            </div>

            <div className="p-2 flex justify-between items-center">
              <div className="flex gap-2">
                <div className="p-1 bg-red-50 border-2 border-red-700 rounded-lg text-red-700 cursor-pointer">
                  <Trash2 />
                </div>
              </div>
              <div className="flex items-center gap-1 cursor-pointer">
                <MessageSquareMore className="text-gray-500" />
                <span className="text-gray-500">8</span>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
