import React, { useState } from 'react'
import { Menu, X } from 'lucide-react';

// import logo from '../assets/logo.png'

import { Link, useNavigate } from 'react-router-dom';
 
export default function Navbar() {
  const navigate = useNavigate();

  const [nav, setNav] = useState(false); 
  const handleClick = () => {
    setNav(!nav); 
  }

  return (
    <>
    <div className='bg-[#000000] h-[60px] fixed top-0 w-full shadow-lg flex items-center px-3 justify-between z-50'>
      <p className='text-2xl font-bold text-white'>LOGO</p>
      <p className='text-black text-sm bg-white p-2 rounded-lg mr-2 font-bold cursor-pointer' onClick={() => navigate('/login')}>Login</p>
    </div>
    </>
  )
}
