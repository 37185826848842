import React from 'react';

const LocationLoader = () => {
  const styles = `
    html, body {
      width: 100%;
      height: 100%;
    }

    .Main {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .MapIcon {
      overflow: visible;
      transform: scale(3);
    }

    .IconPing {
      animation: iconPing 1.5s linear infinite;
      fill: #ea4335;
      transform-origin: center center;
      vector-effect: non-scaling-stroke;
    }

    @keyframes iconPing {
      0% {
        transform: scale(0.9);
        opacity: 0.6;
      }
  
      40% {
        opacity: 0.4;
      }
  
      60% {
        transform: scale(1.5) translate(0, 4px);
        opacity: 0;
      }
  
      100% {
        opacity: 0;
      }
    }
  `;

  return (
    <>
      <style>{styles}</style>
      <div className="Main">
        <svg
          className="MapIcon"
          width="38.875"
          height="64"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#ea4335"
            d="m36.196853,29.295448a19.347406,19.347406 0 0 0 2.681042,-9.856446c0,-10.734591 -8.702347,-19.436938 -19.436938,-19.436938s-19.436938,8.702347 -19.436938,19.436938c0,3.597207 0.976875,6.966293 2.680428,9.856446c5.743512,10.787942 15.637978,20.962654 15.637978,33.587833a1.118532,1.118532 0 1 0 2.237063,0c0,-12.625179 9.793283,-22.854468 15.637365,-33.587833z"
          />
          <path
            id="icon-ping"
            className="IconPing"
            fill="none"
            d="m36.196853,29.295448a19.347406,19.347406 0 0 0 2.681042,-9.856446c0,-10.734591 -8.702347,-19.436938 -19.436938,-19.436938s-19.436938,8.702347 -19.436938,19.436938c0,3.597207 0.976875,6.966293 2.680428,9.856446c5.743512,10.787942 15.637978,20.962654 15.637978,33.587833a1.118532,1.118532 0 1 0 2.237063,0c0,-12.625179 9.793283,-22.854468 15.637365,-33.587833z"
          />
          <circle
            transform="matrix(0.6132299900054932,0,0,0.6132299900054932,140.1699981689453,-341.1199951171875) "
            id="svg_3"
            fill="#811310"
            r="11.179"
            cy="587.97"
            cx="-196.88"
          />
        </svg>
      </div>
    </>
  );
};

export default LocationLoader;
