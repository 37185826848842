import { Icon, divIcon, point } from "leaflet";
import "../App.css";
import "leaflet/dist/leaflet.css";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import userDataServices from "../services";
import { useEffect, useRef, useState } from "react";
// import { SearchControl } from "react-leaflet-search";
// import { PrintControl } from "react-leaflet-easyprint";


export default function Map() {
  useEffect(() => {
    locationData();
  }, []);

  // for printing
  // const [printControl, setPrintControl] = useState(null);
  // useEffect(() => {
  //   if (printControl) {
  //     console.log("Print Control initialized:", printControl);
  //   }
  // }, [printControl]);

  const [location, setLocation] = useState();

  const markers = [
    {
      geocodes: [77.3342734, 28.6682508],
      popUp: "Hello, I am pop up 1",
    },
    // {
    //   geocodes: [48.85, 2.3522],
    //   popUp: "Hello, I am pop up 2",
    // },
    // {
    //   geocodes: [48.855, 2.34],
    //   popUp: "Hello, I am pop up 3",
    // },
  ];

  const customIcon = new Icon({
    iconUrl: require("../assets/placeholder.png"),
    iconSize: [38, 38],
  });

  const createCustomClusterIcon = (cluster) => {
    return new divIcon({
      html: `<div className="cluster-icon">${cluster.getChildCount()}</div>`,
      iconSize: point(33, 33, true),
    });
  };

  // const num = "+918587010116";
  const locationData = async () => {
    const data = await userDataServices.getLocations();
    setLocation(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  //date
  var today = new Date();

  var day = today.getDate();
  var month = today.getMonth() + 1;
  var year = today.getFullYear();

  const dateToday = `${day}-${month}-${year}`;
  // console.log("Today's date is: " + year + "-" + month + "-" + day);

  // let resultObject = location.find((obj) => obj.id === `${day}_path`);
  // if (resultObject) {
  //   console.log(`Object with key ${day}_path:`, resultObject);
  // } else {
  //   console.log(`Object with key ${day}_path not found.`);
  // }

  // console.log(location, resultObject.path);
  // const locationMarkings = resultObject.path.map((data) => ({
  //   geocodes: [data?.latitude, data?.longitude],
  // }));
  // console.log("this is from location markings", locationMarkings);

  // const locationCoordinates = locationMarkings.map((marker) => marker.geocodes);

  return (
    <>
      <MapContainer center={[20.5937, 78.9629]} zoom={5}>
        <TileLayer
          //   attribution=""
          url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {/* printing data */}
        {/* <PrintControl
          ref={(ref) => {
            setPrintControl(ref);
          }}
          position="topleft"
          sizeModes={["Current", "A4Portrait", "A4Landscape"]}
          hideControlContainer={false}
        /> */}

        {/* <SearchControl
          provider={OpenStreetMapProvider()}
          showMarker={false}
          showPopup={false}
          zoom={15}
          position="topright"
          retainZoomLevel={false}
        /> */}

        {/* <Polyline positions={locationCoordinates} color="blue" />

        <MarkerClusterGroup
          chunkedLoading
          // iconCreateFunction={""}
        >
          {locationMarkings.map((marker, index) => (
            <Marker position={marker.geocodes} icon={customIcon} key={index}>
              {console.log(marker.geocodes)}
              <Popup>
                <h2>{"marker.popUp"}</h2>
              </Popup>
            </Marker>
          ))}
        </MarkerClusterGroup> */}
      </MapContainer>
    </>
  );
}
