import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import toast from "react-hot-toast";

export default function Signup() {
  const [values, setValues] = useState({
    name: "",
    email: "",
    mobile: "",
    pass: "",
    confPass: "",
    age: "",
    gender: "",
    referral: "",
  });
  console.log(values);

  const [errorMsg, setErrorMsg] = useState("");
  const [submitButtonDisable, setSubmitButtonDisable] = useState(false);

  const navigate = useNavigate();
  const handleSubmission = async (e) => {
    e.preventDefault();
    if (values.pass !== values.confPass) {
      setErrorMsg("Password does not match");
      return;
    }
    setErrorMsg("");

    if (!values.name || !values.email || !values.pass) {
      setErrorMsg("Fill all fields");
      return;
    }
    setErrorMsg("");

    setSubmitButtonDisable(true);
    e.preventDefault();
    try {
      const res = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.pass,
      );
      const { user } = res;
      const updateResponse = await updateProfile(user, {
        displayName: values.name,
        photoURL: 'not-configured',
        phoneNumber: values.mobile,
      })
      setSubmitButtonDisable(false);
      // profileName(values.name);
      // user.updateProfile({displayName: values.name})
      console.log("res from the function", updateResponse, res);

      toast.success("Signup Successfull");
      navigate("/login");
    } catch (err) {
      setSubmitButtonDisable(false);
      setErrorMsg(err.message);
    }
  };

  return (
    <div className="wrapper signUp">
      <div className="illustration absolute w-[50%] right-[100px]">
        {/* <img src="https://source.unsplash.com/random" alt="illustration" /> */}
      </div>
      <div className="form">
        <div className="heading">CREATE AN ACCOUNT</div>
        <form onSubmit={handleSubmission}>
          <div>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              placeholder="Enter your name"
              onChange={(e) =>
                setValues((prev) => ({ ...prev, name: e.target.value }))
              }
            />
          </div>
          <div>
            <label htmlFor="name">E-Mail</label>
            <input
              type="text"
              id="name"
              placeholder="Enter your mail"
              onChange={(e) =>
                setValues((prev) => ({ ...prev, email: e.target.value }))
              }
            />
          </div>
          <div>
            <label htmlFor="name">Phone Number</label>
            <input
              type="text"
              id="name"
              placeholder="Enter your mail"
              onChange={(e) =>
                setValues((prev) => ({ ...prev, mobile: e.target.value }))
              }
            />
          </div>
          <div>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              placeholder="Enter you password"
              onChange={(e) =>
                setValues((prev) => ({ ...prev, pass: e.target.value }))
              }
            />
          </div>
          <div>
            <label htmlFor="password">Confirm Password</label>
            <input
              type="password"
              id="password"
              placeholder="Enter you password"
              onChange={(e) =>
                setValues((prev) => ({ ...prev, confPass: e.target.value }))
              }
            />
          </div>
          {errorMsg && <p>{errorMsg}</p>}
          <button type="submit">Submit</button>
          <h2 align="center" class="or">
            OR
          </h2>
        </form>
        <p>
          Have an account ? <Link to="/login"> Login </Link>
        </p>
      </div>
    </div>
  );
}
