import { Line } from 'react-chartjs-2'
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js'

ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
)

export default function Chart(props) {
    const data = {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
      datasets: [{
        data: props?.chartData,
        backgroundColor: 'transparent',
        borderColor: props?.color || '#f26d6d',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        // tension: 0.5
      }]
    }

    const options = {
        plugins: {
            legend: false
        },
        scales: {
            x: {
                grid: {
                    display: false,
                }
            },
            y: {
              beginAtZero: true,
            //   min: 2, 
            //   max: 10,
            //   ticks: {
            //     stepSize: 2,
            //     callback: (value) => value + 'K'
            //   },
              grid: {
                borderDash: [10]
              }
            }
          }
    }
    
    return(
        <>
        <Line data={data} options={options}></Line>
        </>
    )
}