import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import toast from 'react-hot-toast';

export default function Login() {

    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();

    const logIn = () =>  signInWithEmailAndPassword(auth, email, password); 

    const handleSubmit = async (e) => {
        setError("");
        e.preventDefault();
        try {
            await logIn(email, password);
			toast.success("Login Successfull");
            navigate("/application");
        } catch (err) {
            setError(err.message)
        }
    };

	return (
		<div className="wrapper signIn">
			<div className="illustration flex justify-center items-center">
				{/* <img src="https://source.unsplash.com/random" alt="illustration" /> */}
				<h1 className='text-white font-[900] text-3xl w-fit'>LOGO</h1>
			</div>
			<div className="form">
				<div className="heading">LOGIN</div>
				<form>
					<div>
						<label htmlFor="email">Email</label>
						<input type="email" id="email" placeholder="Enter your email" onChange={e => setEmail(e.target.value)}/>
					</div>
					<div>
						<label htmlFor="password">Password</label>
						<input type="password" id="password" placeholder="Enter you password" onChange={e => setPassword(e.target.value)}/>
					</div>
					<button type="submit" onClick={handleSubmit}>
						Submit
					</button>
				</form>
                {error && <p style={{ color: 'red', textAlign: 'center'}}>{error.slice(9, 999)}</p>}
				<p>
					Don't have an account ? <Link to="/signup"> Sign Up </Link>
				</p>
			</div>
		</div>
	);
}
