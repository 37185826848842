import { FrameIcon, MapPinned, Mail, Phone } from "lucide-react";

export default function Footer() {
  return (
    <div className="flex justify-between bg-transparent my-4 w-[95%] mx-auto rounded-2xl">
      <div className="bg-white p-3 w-[30%] rounded-2xl shadow-lg flex flex-col justify-between">
        <div>
          <div className="my-3 mx-2 flex gap-2">
            <FrameIcon />
            <p>COMPANY NAME</p>
          </div>
          <p className="text-xs text-gray-500 font-extralight my-3 mx-2">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Magni, vel
            odio. Qui dolore dolorum assumenda distinctio excepturi quas
            dignissimos. Modi laudantium fugiat aspernatur amet voluptatum
            aliquid neque hic. Nemo, ipsam!
          </p>
        </div>
        <p className="bg-[#d0dff7] text-center p-2 mt-12 rounded-xl font-bold text-gray-700 cursor-pointer">
          Log in to start trial
        </p>
      </div>
      <div className="bg-white p-3 w-[69%] rounded-2xl shadow-lg">
        <div className="flex justify-between gap-2">
          <div className="m-2">
            <p className="font-bold">Links</p>
            <div className="text-sm flex flex-col mt-3">
              <a href="no">Privacy Policy</a>
              <a href="no">Terms Of Use</a>
              <a href="no">Community Standards</a>
              <a href="no">Business Policy</a>
              <a href="no">Disclaimer</a>
              <a href="no">Advertisement Policy</a>
            </div>
          </div>
          <div className="m-2 w-[40%]">
            <p className="font-bold">Heading</p>
            <p className="text-sm mt-3">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit
              corporis maiores voluptatum molestiae deleniti, beatae sint, ullam
              nam impedit eaque doloremque quis quidem. Sint, nostrum.
            </p>
          </div>
          <div className="m-2">
            <p className="font-bold">Contact us</p>
            <div className="text-sm mt-3 flex flex-col gap-2">
              <div className="flex gap-2 items-center">
                <MapPinned />
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
              </div>
              <div className="flex gap-2 items-center">
                <Mail />
                <p>example@gmail.com</p>
              </div>
              <div className="flex gap-2 items-center">
                <Phone />
                <p>+91 9999 8888 77</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className="text-xs text-gray-500 font-light text-center">
            comapnyName © 2024
          </p>
        </div>
      </div>
    </div>
  );
}
