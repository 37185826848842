import {
  LayoutDashboard,
  History,
  Bike,
  AppWindow,
  FileText,
  MonitorDot,
  UserRound,
  ChevronRight,
  Globe
} from "lucide-react";
import { useState } from "react";

const menuData = [
  {
    icon: (
      <>
        <Globe size={26} />
      </>
    ),
    title: "Track All",
  },
  {
    icon: (
      <>
        <LayoutDashboard />
      </>
    ),
    title: "Dashboard",
  },
  {
    icon: (
      <>
        <History />
      </>
    ),
    title: "History",
  },
  {
    icon: (
      <>
        <Bike />
      </>
    ),
    title: "Trip",
  },
  {
    icon: (
      <>
        <AppWindow />
      </>
    ),
    title: "Request for App based",
  },
  {
    icon: (
      <>
        <FileText />
      </>
    ),
    title: "Application Data",
  },
  {
    icon: (
      <>
        <MonitorDot />
      </>
    ),
    title: "Developers",
  },
];

export default function Sidebar() {
  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <>
      <div className="w-[400px] m-3 rounded-2xl min-h-[95vh] bg-gradient-to-tr from-[rgba(255, 255, 255, 0.7)] to-[rgba(255, 255, 255, 0.3)]">
        <div className="m-3 rounded-2xl bg-[#1f4037] h-auto text-gray-300 px-3 py-5">
          <div className="bg-red-400 mx-auto rounded-full w-[70px] h-[70px] flex justify-center items-center">
            <UserRound size={35} />
          </div>
          <div className="text-center mt-4">
            <p className="text-white text-xl font-bold">Amar Jyothi</p>
            <p className="text-xs mt-2">amarjyothi526@gmail.com</p>
            <p className="text-xs">+91 9999888899</p>
          </div>
          <p className="text-xs flex gap-1 items-center mx-auto w-[80px] mt-5 text-blue-300 justify-center cursor-pointer">
            See More <ChevronRight size={16} />
          </p>
        </div>

        <div className={`m-3 p-2 flex flex-col`}>
          {menuData.map((data, index) => (
            <div
              key={data.title}
              onClick={() => {
                setActiveIndex(index);
              }}
              className={`text-gray-300 flex p-3 my-[1.5px] cursor-pointer rounded-2xl items-center gap-2 ${
                activeIndex === index && "bg-blue-200 text-red-900"
              }`}
            >
              {data.icon}
              <p>{data.title}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
