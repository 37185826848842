import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Track from './pages/Track';
import Dashboard from './pages/Dashboard';
import Application from './pages/Application';
import Map from './components/Map';
import Login from './pages/Login';
import Signup from './pages/Signup';
import { Toaster } from 'react-hot-toast';
import NotFound404 from './pages/NotFound404';

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/application' element={<Application />}/>
        <Route path='/test' element={<Track />}/>
        <Route path='/map' element={<Map />}/>
        <Route path='/application/dashboard' element={<Dashboard />}/>
        <Route path='/login' element={<Login />}/>
        <Route path='/signup' element={<Signup />}/>
        <Route path="*" element={<NotFound404 />}/>
      </Routes>
    </Router>
    <Toaster />
    </>
  );
}

export default App;
