import Loader from "../components/Loader";
import {
  LayoutDashboard,
  Bike,
  AppWindow,
  FileText,
  MonitorDot,
  UserRound,
  ChevronRight,
  NotepadText,
  MapPin,
  GitPullRequestArrow,
  ClipboardCheck,
  Globe,
  Route,
  IndianRupee,
  Navigation,
  Globe2,
  RadioTower,
  Ban,
} from "lucide-react";
import Map from "../components/Map";
import * as XLSX from "xlsx";

import UsersDataServices from "../services.js";
import { useEffect, useState } from "react";
import Report from "../components/Report.jsx";
import { motion, useAnimation } from "framer-motion";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Analytics from "../components/Analytics.jsx";
import Tasks from "../components/Tasks.jsx";

export default function Application() {
  const [active, setActive] = useState("dashboard");

  const user = "first@gmail.com";

  const [users, setUsers] = useState([]);
  const [expenses, setExpenses] = useState([]);

  const [shake, setShake] = useState(false);
  const controls = useAnimation();
  const [uid, setUid] = useState("");
  const [AccUser, setAccUser] = useState({});

  const [showTimeline, setShowTimeline] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      console.log(auth);
      if (user) {
        setAccUser(user);
        console.log("user", user, uid);
      } else {
        console.log("user is signed out");
      }
    });
  }, []);

  const handleClick = async () => {
    setShake(!shake);

    await controls.start({ x: [-10, 10, -5, 5, 0] });
  };

  useEffect(() => {
    getUsers();
    newFun();
  }, []);

  const getUsers = async () => {
    const data = await UsersDataServices.getAllUsers();
    // console.log(data.docs);
    // setUsers(data);
    setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const newFun = async () => {
    const data = await UsersDataServices.getLocations();
    setExpenses(data);
    // setExpenses(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  // console.log("users", users);
  // console.log("location_data", expenses);

  const downloadExcel = (data, fileName) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  const handleDownload = () => {
    // const combinedData = [...users, ...expenses]; // Combine your two states or use them separately
    const data = users;
    downloadExcel(data, "excelData");
  };

  var today = new Date();

  var day = today.getDate();
  var month = today.getMonth() + 1;
  var year = today.getFullYear();

  const dateToday = `${day}-${month}-${year}`;

  return (
    <>
      {/* <Loader /> */}
      <div className="w-full bg-black h-auto fixed flex justify-between">
        {/* Sidebar */}
        <div className="text-white m-3">
          {/* <h1 className="text-2xl font-bold mx-3">TITLE</h1> */}
          <div className="m-2 rounded-2xl bg-gradient-to-r from-gray-700 to-gray-900 w-[250px] h-[300px] text-gray-300 px-3 py-5">
            <div className="bg-red-400 mx-auto rounded-full w-[70px] h-[70px] flex justify-center items-center">
              <UserRound size={35} />
            </div>
            <div className="text-center mt-4">
              <p className="text-white text-xl font-bold">
                {AccUser?.displayName}
              </p>
              <p className="text-xs mt-2">{AccUser?.email}</p>
              <p className="text-xs">+91 9999888899</p>
            </div>
            <p className="text-xs flex gap-1 items-center mx-auto w-[80px] mt-5 text-blue-300 justify-center cursor-pointer">
              See More <ChevronRight size={16} />
            </p>
          </div>
          <div className="flex flex-col gap-1 mx-2 my-4">
            {/* <p className="px-2 py-1 bg-gray-200 text-black rounded-2xl text-center">{dateToday}</p> */}
            <p
              onClick={() => setActive("dashboard")}
              className={`text-[${
                active === "dashboard" ? "#fff" : "#D3D3D3"
              }] ${
                active === "dashboard" ? "font-extrabold" : "font-extralight"
              } flex gap-3 items-center p-2 cursor-pointer`}
            >
              <LayoutDashboard /> Dashboard
            </p>
            <p
              onClick={() => setActive("monitors")}
              className={`text-[${
                active === "monitors" ? "#fff" : "#D3D3D3"
              }] ${
                active === "monitors" ? "font-extrabold" : "font-extralight"
              } flex gap-3 items-center p-2 cursor-pointer`}
            >
              <MonitorDot /> Monitor
            </p>
            <p onClick={() => setActive("tasks")}
              className={`text-[${
                active === "tasks" ? "#fff" : "#D3D3D3"
              }] ${
                active === "tasks" ? "font-extrabold" : "font-extralight"
              } flex gap-3 items-center p-2 cursor-pointer`}>
              <ClipboardCheck /> Task
            </p>
            {/* <p className="text-[#D3D3D3] font-extralight flex gap-3 items-center p-2 cursor-pointer">
              <Globe /> Settings
            </p> */}
            <p
              onClick={() => setActive("reports")}
              className={`text-[${active === "reports" ? "#fff" : "#D3D3D3"}] ${
                active === "reports" ? "font-extrabold" : "font-extralight"
              } flex gap-3 items-center p-2 cursor-pointer`}
            >
              <FileText /> Reports
            </p>
            <p className="text-[#D3D3D3] font-extralight flex gap-3 items-center p-2 cursor-pointer">
              <GitPullRequestArrow /> Request
            </p>
            <p onClick={() => setActive("analytics")} className={`text-[${active === "analytics" ? "#fff" : "#D3D3D3"}] ${
                active === "analytics" ? "font-extrabold" : "font-extralight"
              } flex gap-3 items-center p-2 cursor-pointer`}>
              <AppWindow /> Analytics
            </p>
            <p className="text-[#D3D3D3] font-extralight flex gap-3 items-center p-2 cursor-pointer">
              <Bike /> Support
            </p>
          </div>
        </div>
        {/* Main Screen */}
        <div className="w-[80%] h-[100vh] bg-[#fff] flex justify-between">
          {/* Dashboard */}
          {active === "dashboard" && (
            <div>
              <div className="mx-4 my-4 grid grid-cols-2 grid-rows-2 gap-4">
                <div className="flex gap-1 items-center">
                  <p className="p-1 w-[35px] bg-blue-600 text-white rounded-xl font-bold text-center">
                    198
                  </p>
                  <p>All Employee</p>
                </div>

                <div className="flex gap-1 items-center">
                  <p className="p-1 bg-green-600 text-white rounded-xl font-bold w-[35px] text-center">
                    60
                  </p>
                  <p>Punched In</p>
                </div>

                <div className="flex gap-1 items-center">
                  <p className="p-1 w-[35px] bg-red-600 text-white rounded-xl font-bold text-center">
                    38
                  </p>
                  <p>Punched Out</p>
                </div>

                <div className="flex gap-1 items-center">
                  <p className="p-1 w-[35px] bg-yellow-500 text-white rounded-xl font-bold text-center">
                    38
                  </p>
                  <p>On Leave</p>
                </div>
              </div>

              {/* <p
              className="text-gray-50 cursor-pointer bg-blue-500 w-fit rounded-2xl p-2 mx-4 mb-3"
              onClick={handleDownload}
            >
              download user data
            </p> */}
              <div className="h-[80vh] overflow-y-scroll">
                <div className="w-[450px] h-auto shadow-sm rounded-2xl mx-6 mb-3">
                  <div>
                    <div className="flex items-center gap-3 p-2 shadow-2xl">
                      <p className="bg-gray-600 shadow-xl rounded-full h-[40px] text-white text-center w-[40px] flex items-center justify-center font-extrabold text-lg">
                        AJ
                      </p>
                      <div>
                        <p className="font-bold">Amar Jyothi</p>
                        <p className="text-xs text-gray-500">
                          {" "}
                          +91 99995 44443
                        </p>
                      </div>
                    </div>
                    <div className="bg-green-100 flex p-2 justify-between">
                      <p className="font-bold text-sm text-green-700">
                        Punched IN: Today at 8:43 am
                      </p>
                      <p
                        onClick={() => setShowTimeline(!showTimeline)}
                        className="flex gap-1 items-center font-light cursor-pointer"
                      >
                        Timeline <NotepadText color="#0096FF" />
                      </p>
                    </div>
                    <div className="bg-green-50 flex gap-4 justify-between p-2">
                      <div
                        onClick={handleClick}
                        className="flex items-center gap-1 w-fit cursor-pointer"
                      >
                        <motion.div animate={controls}>
                          <MapPin />
                        </motion.div>
                        <p className="font-bold text-sm">Location</p>
                      </div>
                      <div>
                        <p className="text-gray-600 text-sm">
                          35 A, Devika Tower, Chander Nagar, Delhi NCR Lorem
                          ipsum dolor sit amet consectetur, adipisicing elit.
                          Eos, repudiandae.
                        </p>
                      </div>
                    </div>
                  </div>
                  {showTimeline && (
                    <div className="h-[40vh] overflow-y-scroll">
                      <div className="flex gap-2 items-center p-2 mt-2">
                        <div className="w-[150px]">
                          <p className="text-xs font-bold text-green-600">
                            2:25 pm
                          </p>
                          <p className="text-xs font-light">21 Feb 2024</p>
                        </div>
                        <p className="text-xs text-gray-600 font-light">
                          35 A, Devika Tower, Chander Nagar, Delhi NCR Lorem
                          ipsum dolor sit amet consectetur, adipisicing elit.
                          Eos, repudiandae.
                        </p>
                      </div>
                      <div className="flex gap-2 items-center p-2 mt-2">
                        <div className="w-[150px]">
                          <p className="text-xs font-bold text-green-600">
                            2:25 pm
                          </p>
                          <p className="text-xs font-light">21 Feb 2024</p>
                        </div>
                        <p className="text-xs text-gray-600 font-light">
                          35 A, Devika Tower, Chander Nagar, Delhi NCR Lorem
                          ipsum dolor sit amet consectetur, adipisicing elit.
                          Eos, repudiandae.
                        </p>
                      </div>
                      <div className="flex gap-2 items-center p-2 mt-2">
                        <div className="w-[150px]">
                          <p className="text-xs font-bold text-green-600">
                            2:25 pm
                          </p>
                          <p className="text-xs font-light">21 Feb 2024</p>
                        </div>
                        <p className="text-xs text-gray-600 font-light">
                          35 A, Devika Tower, Chander Nagar, Delhi NCR Lorem
                          ipsum dolor sit amet consectetur, adipisicing elit.
                          Eos, repudiandae.
                        </p>
                      </div>
                      <div className="flex gap-2 items-center p-2 mt-2">
                        <div className="w-[150px]">
                          <p className="text-xs font-bold text-green-600">
                            2:25 pm
                          </p>
                          <p className="text-xs font-light">21 Feb 2024</p>
                        </div>
                        <p className="text-xs text-gray-600 font-light">
                          35 A, Devika Tower, Chander Nagar, Delhi NCR Lorem
                          ipsum dolor sit amet consectetur, adipisicing elit.
                          Eos, repudiandae.
                        </p>
                      </div>
                      <div className="flex gap-2 items-center p-2 mt-2">
                        <div className="w-[150px]">
                          <p className="text-xs font-bold text-green-600">
                            2:25 pm
                          </p>
                          <p className="text-xs font-light">21 Feb 2024</p>
                        </div>
                        <p className="text-xs text-gray-600 font-light">
                          35 A, Devika Tower, Chander Nagar, Delhi NCR Lorem
                          ipsum dolor sit amet consectetur, adipisicing elit.
                          Eos, repudiandae.
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                <div className="w-[450px] h-auto shadow-sm rounded-2xl mx-6 mb-2">
                  {/* <div className="flex justify-between py-1 px-2">
                  <p className="text-red-600 font-bold">+918587010116</p>
                  <div className="flex gap-1 cursor-pointer items-center">
                    <p className="font-bold">Trail</p>
                    <Route size={18} />
                  </div>
                </div>
                <p className="px-2 text-xs text-green-800 cursor-pointer">
                  (Add User Name)
                </p>
                <div className="flex justify-end px-2">
                  <p className="text-sm font-bold text-black bg-[#fffff2] py-1 px-2 rounded-lg shadow-sm cursor-pointer">
                    Locate
                  </p>
                </div> */}

                  <div>
                    <div className="flex items-center gap-3 p-2 shadow-2xl">
                      <p className="bg-gray-600 shadow-xl rounded-full h-[40px] text-white text-center w-[40px] flex items-center justify-center font-extrabold text-lg">
                        OE
                      </p>
                      <div>
                        <p className="font-bold">Other Employee</p>
                        <p className="text-xs text-gray-500">
                          {" "}
                          +91 00000 00000
                        </p>
                      </div>
                    </div>
                    <div className="bg-red-100 flex p-2 justify-between">
                      <p className="font-bold text-sm text-red-700">
                        Punched OUT: Today at 8:43 am
                      </p>
                      <p className="flex gap-1 items-center font-light cursor-pointer">
                        Timeline <NotepadText color="#0096FF" />
                      </p>
                    </div>
                    <div className="bg-red-50 flex gap-4 justify-between p-2">
                      <div className="flex items-center gap-1 w-fit cursor-pointer">
                        <MapPin />
                        <p className="font-bold text-sm">Location</p>
                      </div>
                      <div>
                        <p className="text-gray-600 text-sm">
                          35 A, Devika Tower, Chander Nagar, Delhi NCR Lorem
                          ipsum dolor sit amet consectetur, adipisicing elit.
                          Eos, repudiandae.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Monitor */}
          {active === "monitors" && (
            <div className="mx-4 my-4 w-[450px]">
              <p className="font-bold text-xl text-center w-[450px] mb-2">
                Monitor
              </p>
              <div className="flex flex-col gap-2"> 
              <div className="w-[450px] h-auto shadow-sm rounded-2xl mx-2 mb-2">
                <div>
                  <div className="flex justify-between shadow-2xl">
                    <div className="flex items-center gap-3 p-2">

                    <p className="bg-gray-600 shadow-xl rounded-full h-[40px] text-white text-center w-[40px] flex items-center justify-center font-extrabold text-lg">
                      E01
                    </p>
                    <div>
                      <p className="font-bold">Employee 01</p>
                      <p className="text-xs text-gray-500"> +91 00000 00000</p>
                    </div>
                    </div>

                    <div className="flex items-center gap-1 mr-2"> 
                    <RadioTower className="text-green-600"/>
                    <p className="text-green-600 font-extrabold">Live</p>
                    </div>
                  </div>
                  <div className="bg-[#92C7CF] flex p-2 justify-between rounded-b-2xl">
                    <p className="font-bold text-sm text-black">
                      Punch IN time: 2:23 pm
                    </p>
                    <p className="flex gap-1 items-center font-light cursor-pointer">
                      Locate <MapPin color="#0096FF" />
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-[450px] h-auto shadow-sm rounded-2xl mx-2 mb-2">
                <div>
                  <div className="flex justify-between shadow-2xl">
                    <div className="flex items-center gap-3 p-2">

                    <p className="bg-gray-600 shadow-xl rounded-full h-[40px] text-white text-center w-[40px] flex items-center justify-center font-extrabold text-lg">
                      E02
                    </p>
                    <div>
                      <p className="font-bold">Employee 02</p>
                      <p className="text-xs text-gray-500"> +91 00000 00000</p>
                    </div>
                    </div>

                    <div className="flex items-center gap-1 mr-2"> 
                    <RadioTower className="text-green-600"/>
                    <p className="text-green-600 font-extrabold">Live</p>
                    </div>
                  </div>
                  <div className="bg-[#B4D4FF] flex p-2 justify-between rounded-b-2xl">
                    <p className="font-bold text-sm text-black">
                      Punch IN time: 2:23 pm
                    </p>
                    <p className="flex gap-1 items-center font-light cursor-pointer">
                      Locate <MapPin color="#0096FF" />
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-[450px] h-auto shadow-sm rounded-2xl mx-2 mb-2">
                <div>
                  <div className="flex justify-between shadow-2xl">
                    <div className="flex items-center gap-3 p-2">

                    <p className="bg-gray-600 shadow-xl rounded-full h-[40px] text-white text-center w-[40px] flex items-center justify-center font-extrabold text-lg">
                      E03
                    </p>
                    <div>
                      <p className="font-bold">Employee 03</p>
                      <p className="text-xs text-gray-500"> +91 00000 00000</p>
                    </div>
                    </div>

                    <div className="flex items-center gap-1 mr-2"> 
                    {/* <RadioTower className="text-green-600"/> */}
                    <Ban className="text-red-600" />
                    <p className="text-red-600 font-extrabold">Offline</p>
                    </div>
                  </div>
                  <div className="bg-[#F9F9E0] flex p-2 justify-between rounded-b-2xl">
                    <p className="font-bold text-sm text-red-700">
                      Punch OUT time: 5:23 pm
                    </p>
                    <p className="flex gap-1 items-center font-light cursor-pointer">
                      Locate <MapPin color="#0096FF" />
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-[450px] h-auto shadow-sm rounded-2xl mx-2 mb-2">
                <div>
                  <div className="flex justify-between shadow-2xl">
                    <div className="flex items-center gap-3 p-2">

                    <p className="bg-gray-600 shadow-xl rounded-full h-[40px] text-white text-center w-[40px] flex items-center justify-center font-extrabold text-lg">
                      E04
                    </p>
                    <div>
                      <p className="font-bold">Employee 04</p>
                      <p className="text-xs text-gray-500"> +91 00000 00000</p>
                    </div>
                    </div>

                    <div className="flex items-center gap-1 mr-2"> 
                    <RadioTower className="text-green-600"/>
                    <p className="text-green-600 font-extrabold">Live</p>
                    </div>
                  </div>
                  <div className="bg-[#FFC0D9] flex p-2 justify-between rounded-b-2xl">
                    <p className="font-bold text-sm text-black">
                      Punch IN time: 2:23 pm
                    </p>
                    <p className="flex gap-1 items-center font-light cursor-pointer">
                      Locate <MapPin color="#0096FF" />
                    </p>
                  </div>
                </div>
              </div>
              </div>
            </div>
          )}

          {/* Report */}
          {active === "reports" && <Report />}

          {/* Tasks */}
          {active === "tasks" && <Tasks />}

          {/* Analytics */}
          {active === "analytics" && <Analytics />}

          {active !== "reports" && active !== "analytics" && (
            <div className="rounded-2xl h-[95vh] shadow-2xl w-[600px] m-3 object-cover">
              <Map />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
