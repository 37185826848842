import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

export default function Track() {
  return (
    <>
      {/* <Navbar /> */}
      <div className="min-h-[100vh] bg-gradient-to-r from-[#1f4037] to-[#99f2c8] h-screen">
        <div className="flex">
          <Sidebar />
          <div className="flex gap-3 min-h-[95vh] w-full my-3 mr-3 rounded-2xl bg-gradient-to-bl from-[rgba(255, 255, 255, 0.7)] to-[rgba(255, 255, 255, 0.3)]">
            <div className="w-[700px]">
              <p className="text-white text-center text-2xl font-bold tracking-wide my-3">
                Mobile
              </p>
              <div className="min-h-[80vh] my-3 flex flex-col gap-2">
                <div className="bg-[#0f0c29] rounded-3xl ml-3 min-h-[120px]"></div>
                <div className="bg-[#0f0c29] rounded-3xl ml-3 min-h-[120px]"></div>
                <div className="bg-[#0f0c29] rounded-3xl ml-3 min-h-[120px]"></div>
              </div>
              <p className="text-white font-bold p-3 bg-slate-700 w-fit rounded-3xl mx-auto">
                Add Phone Number
              </p>
            </div>
            <div className="min-h-[95vh] w-full rounded-2xl bg-blue-950 m-2">
              <img
                className="rounded-2xl h-[95vh] object-cover"
                src="https://developers.google.com/static/codelabs/maps-platform/full-stack-store-locator/img/58a6680e9c8e7396.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
